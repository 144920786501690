<template>
  <div
    class="cy-details sticky-footer__viewport new-header">
    <div class="cy-details__content">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="secondary"/>

      <div
        v-else
        class="cy-details__content__section cy-details__content__section--contained">
        <!-- No External Backends Msg -->
        <!-- TODO: FE#6031 pass slot contents from context -->
        <slot name="description">
          <v-card-title>
            <p class="description">
              {{ $t('externalBackendsConfig.description.setupLocationOf') }}
              <a
                class="cy-link"
                target="_blank"
                rel="noopener noreferrer"
                :href="$docLinks.terraform.state">
                {{ $t('externalBackendsConfig.description.tfFile') }}
              </a>
              {{ $t('externalBackendsConfig.description.forDesiredEnv') }}
            </p>
          </v-card-title>
        </slot>

        <!-- External Backends Form -->
        <div class="form">
          <CyNotification
            class="my-2"
            theme="error"
            :content="externalBackendErrors"/>

          <!-- List of Envs with External Backend -->
          <v-select
            v-if="useEnv"
            v-model="$v.externalBackend.environmentCanonical.$model"
            :items="filteredEnvs"
            :label="$t('forms.fieldOrgEnv')"
            item-value="canonical"
            item-text="canonical"
            :error-messages="envErrors"
            :menu-props="{ offsetY: true }"
            :append-icon="isCreation ? '$vuetify.icons.dropdown' : ''"
            :readonly="isReadOnly || !_.$isEmpty(isInInfraImport) || !isCreation"
            :disabled="isReadOnly || !_.$isEmpty(isInInfraImport) || !isCreation"
            required
            class="fieldset required-field cy-details__environment-field"
            @blur="$v.externalBackend.environmentCanonical.$touch()">
            <template #selection="{ item: { canonical, color, icon } }">
              <span class="d-flex align-center">
                <CyAvatar
                  :item="{ icon, color }"
                  :disabled="isReadOnly || !_.$isEmpty(isInInfraImport) || !isCreation"
                  class="mr-2"
                  sm/>
                <span>
                  {{ canonical }}
                </span>
              </span>
            </template>
            <template #item="{ item: { canonical, color, icon }, on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on">
                <span class="pipeline-name d-flex align-center">
                  <CyAvatar
                    :item="{ icon, color }"
                    class="mr-2"
                    sm/>
                  <span>
                    {{ canonical }}
                  </span>
                </span>
              </v-list-item>
            </template>
          </v-select>

          <!-- Provider Select -->
          <v-select
            ref="externalBackendTypeSelect"
            v-model="$v.externalBackend.configuration.engine.$model"
            :items="externalBackendTypes"
            :label="$t('provider')"
            :error-messages="configErrors.engine"
            :menu-props="{ offsetY: true }"
            item-disabled="notSupported"
            item-value="engine"
            required
            :readonly="isReadOnly || !_.$isEmpty(isInInfraImport)"
            :disabled="isReadOnly || !_.$isEmpty(isInInfraImport)"
            open-on-clear
            hide-selected
            class="fieldset required-field"
            @blur="$v.externalBackend.configuration.engine.$touch()">
            <template #selection="{ item }">
              <v-avatar
                :key="item.engine"
                class="mr-2"
                size="30">
                <CyIconCredential :type="externalBackendIcon(item.type, item.engine)"/>
              </v-avatar>
              {{ item.text }}
            </template>
            <template #item="{ item, on, attrs }">
              <v-list-item
                :key="item.engine"
                v-bind="attrs"
                v-on="on">
                <v-list-item-avatar>
                  <CyIconCredential :type="externalBackendIcon(item.type, item.engine)"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="external-backend-option__text">
                    {{ item.text }}
                    <CyTag
                      v-if="item.notSupported"
                      variant="accent"
                      small
                      coming-soon>
                      {{ $t('comingSoon') }}
                    </CyTag>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>

          <!-- External Backend Types -->
          <template v-if="externalBackend.configuration.engine">
            <v-select
              :key="`${externalBackend.configuration.engine}:${externalBackend.credentialCanonical}`"
              v-model="$v.externalBackend.credentialCanonical.$model"
              :items="credentials"
              :label="$t('Credential')"
              :error-messages="credentialErrors"
              :loading="fetchInProgress.credentials"
              :menu-props="{ maxHeight: 304, offsetY: true }"
              :readonly="isReadOnly"
              :disabled="isReadOnly"
              item-value="canonical"
              item-text="name"
              required
              class="fieldset required-field"
              @blur="$v.externalBackend.credentialCanonical.$touch()">
              <template #selection="{ item }">
                <v-avatar
                  class="mr-2"
                  size="30">
                  <CyIconCredential
                    :type="item.type"
                    medium
                    show-tooltip/>
                </v-avatar>
                <div>{{ item.name }}</div>
              </template>

              <template #item="{ item }">
                <v-list-item-avatar>
                  <CyIconCredential
                    :key="`${externalBackend.configuration.engine}_${item.type}`"
                    :type="item.type"
                    medium
                    show-tooltip/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <!-- AWSStorage -->
            <div v-if="isAddingExternalBackendFor('AWSStorage')">
              <v-autocomplete
                v-model="$v.externalBackend.configuration.region.$model"
                :items="awsRegions"
                :label="$t('untranslated.region')"
                :placeholder="getDescriptionKey('AWSStorage', 'region')"
                :error-messages="configErrors.region"
                :filter="filterRegions"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                required
                class="fieldset required-field">
                <template #item="{ item }">
                  {{ item.text }} <span class="ml-2 form__region">{{ item.value }}</span>
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="$v.externalBackend.configuration.bucket.$model"
                :label="$t('untranslated.bucket')"
                :hint="getDescriptionKey('AWSStorage', 'bucket')"
                :error-messages="configErrors.bucket"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.bucket.$touch()"/>
              <v-text-field
                v-if="!skipFields.includes('key')"
                v-model="$v.externalBackend.configuration.key.$model"
                :label="$t('untranslated.key')"
                :hint="getDescriptionKey('AWSStorage', 'key')"
                :error-messages="configErrors.key"
                persistent-hint
                required
                :readonly="isReadOnly || !_.$isEmpty(isInInfraImport)"
                :disabled="isReadOnly || !_.$isEmpty(isInInfraImport)"
                class="fieldset required-field mt-4"
                @blur="$v.externalBackend.configuration.key.$touch()"/>

              <fieldset class="form--fieldset">
                <h2 class="form--fieldset__title">
                  {{ $t('externalBackendsConfig.s3StorageCompatibleOptions') }}
                </h2>
                <v-checkbox
                  v-model="$v.externalBackend.configuration.s3_force_path_style.$model"
                  :label="$t('untranslated.S3ForcePathStyle')"
                  :hint="getDescriptionKey('AWSStorage', 's3ForcePathStyle')"
                  :readonly="isReadOnly"
                  :disabled="isReadOnly"
                  persistent-hint
                  @blur="$v.externalBackend.configuration.s3_force_path_style.$touch()"/>
                <v-checkbox
                  v-model="$v.externalBackend.configuration.skip_verify_ssl.$model"
                  :label="$t('externalBackendsConfig.awsLabelSkipVerifySSL')"
                  :readonly="isReadOnly"
                  :disabled="isReadOnly"
                  persistent-hint
                  :hint="getDescriptionKey('AWSStorage', 'skipVerifySSL')"/>
                <v-text-field
                  v-model="$v.externalBackend.configuration.endpoint.$model"
                  :label="$t('untranslated.endpoint')"
                  :hint="getDescriptionKey('AWSStorage', 'endpoint')"
                  :readonly="isReadOnly"
                  :disabled="isReadOnly"
                  persistent-hint
                  @blur="$v.externalBackend.configuration.endpoint.$touch()"/>
              </fieldset>
            </div>

            <!-- SwiftStorage -->
            <template v-if="isAddingExternalBackendFor('SwiftStorage')">
              <v-text-field
                v-model="$v.externalBackend.configuration.region.$model"
                :label="$t('untranslated.region')"
                :hint="getDescriptionKey('SwiftStorage', 'region')"
                :error-messages="configErrors.region"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.region.$touch()"/>
              <v-text-field
                v-model="$v.externalBackend.configuration.container.$model"
                :label="$t('untranslated.container')"
                :hint="getDescriptionKey('SwiftStorage', 'container')"
                :error-messages="configErrors.container"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.container.$touch()"/>
              <v-text-field
                v-if="!skipFields.includes('object')"
                v-model="$v.externalBackend.configuration.object.$model"
                :label="$t('untranslated.object')"
                :hint="getDescriptionKey('SwiftStorage', 'object')"
                :error-messages="configErrors.object"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @clear="externalBackend.configuration.object = null"
                @blur="$v.externalBackend.configuration.object.$touch()"/>
              <fieldset class="form--fieldset">
                <v-checkbox
                  v-model="$v.externalBackend.configuration.skip_verify_ssl.$model"
                  :label="$t('externalBackendsConfig.swiftLabelSkipVerifySSL')"
                  :readonly="isReadOnly"
                  :disabled="isReadOnly"
                  persistent-hint
                  :hint="getDescriptionKey('SwiftStorage', 'skipVerifySSL')"/>
              </fieldset>
            </template>

            <!-- AzureStorage -->
            <template v-if="isAddingExternalBackendFor('AzureStorage')">
              <v-text-field
                v-model="$v.externalBackend.configuration.container.$model"
                :label="$t('untranslated.container')"
                :hint="getDescriptionKey('AzureStorage', 'container')"
                :error-messages="configErrors.container"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.container.$touch()"/>
              <v-text-field
                v-if="!skipFields.includes('blob')"
                v-model="$v.externalBackend.configuration.blob.$model"
                :label="$t('untranslated.blob')"
                :hint="getDescriptionKey('AzureStorage', 'blob')"
                :error-messages="configErrors.blob"
                persistent-hint
                required
                :readonly="isReadOnly || !_.$isEmpty(isInInfraImport)"
                :disabled="isReadOnly || !_.$isEmpty(isInInfraImport)"
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.blob.$touch()"/>
            </template>

            <!-- GCPStorage -->
            <template v-if="isAddingExternalBackendFor('GCPStorage')">
              <v-text-field
                v-model="$v.externalBackend.configuration.bucket.$model"
                :label="$t('untranslated.bucket')"
                :hint="getDescriptionKey('GCPStorage', 'bucket')"
                :error-messages="configErrors.bucket"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.bucket.$touch()"/>
              <v-text-field
                v-if="!skipFields.includes('object')"
                v-model="$v.externalBackend.configuration.object.$model"
                :label="$t('untranslated.object')"
                :hint="getDescriptionKey('GCPStorage', 'object')"
                :error-messages="configErrors.object"
                persistent-hint
                required
                :readonly="isReadOnly || !_.$isEmpty(isInInfraImport)"
                :disabled="isReadOnly || !_.$isEmpty(isInInfraImport)"
                class="fieldset required-field"
                @clear="externalBackend.configuration.object = null"
                @blur="$v.externalBackend.configuration.object.$touch()"/>
            </template>

            <!-- GitlabHTTPStorage -->
            <template v-if="isAddingExternalBackendFor('GitLabHTTPStorage')">
              <v-text-field
                v-model="$v.externalBackend.configuration.url.$model"
                :label="$t('untranslated.gitlabUrl')"
                :hint="getDescriptionKey('GitLabHTTPStorage', 'url')"
                :error-messages="configErrors.url"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.url.$touch()"/>
            </template>

            <!-- HTTPStorage -->
            <template v-if="isAddingExternalBackendFor('HTTPStorage')">
              <v-text-field
                v-model="$v.externalBackend.configuration.url.$model"
                :label="$t('untranslated.URL')"
                :hint="getDescriptionKey('HTTPStorage', 'url')"
                :error-messages="configErrors.url"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                persistent-hint
                required
                class="fieldset required-field"
                @blur="$v.externalBackend.configuration.url.$touch()"/>
            </template>

            <v-text-field
              v-if="externalBackend.jwt"
              :value="externalBackend.jwt"
              :label="$t('untranslated.JWT')"
              :hint="$t('externalBackendsConfig.fieldDescription.jwt')"
              persistent-hint
              readonly
              disabled>
              <template #append>
                <CyCopyBtn
                  left
                  :copy-value="JSON.stringify(externalBackend.jwt || '')"/>
              </template>
            </v-text-field>
          </template>
        </div>
      </div>
    </div>

    <!-- TODO: FE#6031 pass slot contents from context -->
    <slot name="actions">
      <div class="cy-details__actions sticky-footer__footer">
        <CyButton
          key="cy-details__cancel-btn"
          :disabled="!canCancel"
          class="cy-details__button"
          variant="secondary"
          theme="primary"
          icon="close"
          @click.native="cancel">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          v-if="!isCreation"
          key="cy-details__save-btn"
          v-has-rights-to="['UpdateExternalBackend', projectCanonical]"
          :loading="isSaving"
          :disabled="!canSave"
          class="cy-details__button"
          variant="primary"
          theme="secondary"
          icon="check"
          type="submit"
          @click.native="update">
          {{ $t('forms.btnSave') }}
        </CyButton>
        <CyButton
          v-else
          key="cy-details__save-btn--creation"
          v-has-rights-to="'CreateExternalBackend'"
          :disabled="!canSave"
          :loading="isSaving"
          class="cy-details__button"
          variant="primary"
          theme="success"
          icon="check"
          type="submit"
          @click.native="create">
          {{ $t('forms.btnCreate') }}
        </CyButton>
      </div>
    </slot>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { constructBreadcrumb, checksPass } from '@/utils/helpers'
import { INFRAVIEW_EXTERNAL_BACKENDS } from '@/utils/config/external-backends'
import AWS_REGIONS from '@/utils/config/aws-regions'
import CyCopyBtn from '@/components/copy-btn'

export const EBValidations = {
  BASE: {
    engine: { required },
  },
  AWSStorage: {
    region: { required },
    bucket: { required },
    key: { required },
    endpoint: {},
    s3_force_path_style: {},
    skip_verify_ssl: {},
  },
  SwiftStorage: {
    region: { required },
    container: { required },
    object: { required },
    skip_verify_ssl: {},
  },
  AzureStorage: {
    container: { required },
    blob: { required },
  },
  GCPStorage: {
    bucket: { required },
    object: { required },
  },
  GitLabHTTPStorage: {
    url: { required },
  },
  HTTPStorage: {
    url: { required },
  },
}

export const allConfigKeys = _(EBValidations)
  .values()
  .reduce((acc, cur) => {
    const keys = _(cur).keys().without('region', 'engine')
    return _.uniq([...acc, ...keys])
  }, [])

export const booleanKeys = [
  's3_force_path_style',
  'skip_verify_ssl',
]

export const baseConfiguration = _.mapValues({ ...EBValidations }, (config) => {
  return _.mapValues(config, (_value, key) => booleanKeys.includes(key) ? false : '')
})

export function suggested (key, { orgCanonical, projectCanonical, env, region, url }) {
  switch (key) {
    case 'bucket':
    case 'container':
      return `${orgCanonical}-terraform-remote-state`

    case 'key':
    case 'object':
    case 'blob':
      return `${projectCanonical}/${env}/${projectCanonical}-${env}.tfstate`

    case 'region':
      return region

    case 'url':
      return url

    case 's3_force_path_style':
    case 'skip_verify_ssl':
      return false

    default:
      return ''
  }
}

export default {
  name: 'CyProjectConfigurationDetail',
  components: {
    CyCopyBtn,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectConfigInfraView'), [
      {
        label: this.$t('routes.projectConfiguration'),
        name: 'projectConfiguration',
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    if (this.$isCreationRoute) return { title: this.$t('routes.projectConfigInfraView') }

    return {
      title: this.$t('externalBackends.RemoteTfBackend'),
      description: {
        text: `${this.$t('externalBackends.configureHint', { files: this.$t('externalBackends.terraformStateFiles') })}`,
        link: $docLinks.terraform.state,
      },
    }
  },
  props: {
    envCanonical: {
      type: String,
      default: null,
    },
    externalBackendId: {
      type: Number,
      default: null,
    },
    isInInfraImport: {
      type: Object,
      default: null,
    },
    presets: {
      type: Object,
      default: null,
    },
    useEnv: {
      type: Boolean,
      default: true,
    },
    createMode: {
      type: Boolean,
      default: null,
    },
    useSuggestions: {
      type: Boolean,
      default: true,
    },
    skipFields: {
      type: Array,
      default: () => [],
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  validations () {
    const validations = {
      externalBackend: {
        purpose: { required },
        credentialCanonical: { required },
        configuration: { ...this.externalBackendValidations },
      },
    }

    if (this.useEnv) validations.externalBackend.environmentCanonical = { required }

    return validations
  },
  data: () => ({
    isSaving: false,
    isLoading: true,
    externalBackend: {
      purpose: null,
      environmentCanonical: null,
      credentialCanonical: '',
      configuration: {
        engine: null,
        url: null,
      },
    },
  }),
  computed: {
    ...mapState('organization', {
      fetchInProgress: (state) => state.fetchInProgress,
      cloudProviders: (state) => state.available.cloudProviders,
    }),
    ...mapState('organization/externalBackend', {
      defaultExternalBackend: (state) => state.detail || {},
      defaultExternalBackendErrors: (state) => state.errors,
    }),
    ...mapState('organization/infraImport', {
      infraImportProject: (state) => state.project,
    }),
    ...mapState('organization/project', {
      externalBackendErrors: (state) => state.errors.externalBackend,
      externalBackends: (state) => state.externalBackends,
      storeExternalBackend: (state) => state.externalBackend || {},
    }),
    ...mapGetters([
      'projectCanonical',
    ]),
    ...mapGetters('organization', [
      'getCredentialsByType',
    ]),
    ...mapGetters('organization/project', [
      'envs',
      'hasNoExternalBackendErrors',
    ]),
    awsRegions () {
      const regionCanonicals = _.find(this.cloudProviders, ({ canonical }) => canonical === 'aws')?.regions || []
      return regionCanonicals.map((region) => ({ text: _.find(AWS_REGIONS, ['value', region])?.text || region, value: region }))
    },
    isCreation () {
      return this.createMode ?? this.$isCreationRoute
    },
    externalBackendValidations () {
      const { configuration: { engine }, purpose } = this.externalBackend
      const runtimeValidations = _.omit(_.get(EBValidations, engine, {}), this.skipFields)
      return {
        ...EBValidations.BASE,
        ...(purpose ? runtimeValidations : {}),
      }
    },
    externalBackendTypes () {
      return _.entries(INFRAVIEW_EXTERNAL_BACKENDS)
        .map(([engine, { supported, ...rest }]) => ({ engine, notSupported: !supported, ...rest }))
    },
    canCancel () {
      return this.$hasDataChanged('externalBackend')
    },
    canSave () {
      const checks = {
        hasCredential: !!this.externalBackend.credentialCanonical,
        hasPurpose: !!this.externalBackend.purpose,
        hasEngine: !!this.externalBackend.configuration.engine,
        hasCompletedConfig: !this.$v.$invalid,
        hasChanges: this.$hasDataChanged('externalBackend'),
      }

      if (this.useEnv) checks.hasEnv = !!this.externalBackend.environmentCanonical

      return checksPass(checks)
    },
    filteredEnvs () {
      const envsWithEB = _.map(this.externalBackends, 'environment_canonical')
      const filtered = this.envs
        .filter(({ canonical }) => !envsWithEB.includes(canonical))
        .map(({ canonical, color, icon }) => ({ canonical, color, icon }))

      if (!_.isEmpty(this.isInInfraImport) && this.infraImportProject) return [this.infraImportProject.environment]

      return !this.isCreation || _.isEmpty(filtered)
        ? [_.find(this.envs, { canonical: this.externalBackend.environmentCanonical })]
        : filtered
    },
    credentialErrors () {
      const errors = []
      const { $dirty, required } = this.$v.externalBackend.credentialCanonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    envErrors () {
      const errors = []
      const { $dirty, required } = this.$v.externalBackend.environmentCanonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    configErrors () {
      return _(EBValidations)
        .$flattenObject()
        .entries()
        .reduce((configErrors, [keyPath]) => {
          const key = keyPath.split('.')[1]
          return {
            ...configErrors,
            ...(configErrors?.[key] ?? {
              [key]: (() => {
                const errors = []
                if (!this.$v.externalBackend.configuration?.[key]) return []
                const { $dirty, required } = this.$v.externalBackend.configuration[key]
                if (!$dirty) return errors
                if (!required) errors.push(this.$t('forms.fieldRequired'))
                return errors
              })(),
            }),
          }
        }, {})
    },
    externalBackendOutput () {
      return {
        ..._.omitBy(_.$snakeCaseKeys(this.externalBackend), _.isNil),
        configuration: _.omitBy(this.externalBackend.configuration, _.isNil),
      }
    },
    credentials () {
      const { type } = INFRAVIEW_EXTERNAL_BACKENDS[this.externalBackend.configuration.engine] || {}
      return this.getCredentialsByType(type)
    },
    hasErrors () {
      return this.isDefault
        ? !_.isEmpty(this.defaultExternalBackendErrors)
        : !this.hasNoExternalBackendErrors
    },
  },
  watch: {
    externalBackendOutput: {
      handler (newVal) {
        this.$emit('change', newVal)
      },
      deep: true,
    },
    canCancel (newVal) {
      this.$emit('update-can-cancel', newVal)
    },
    canSave (newVal) {
      this.$emit('update-can-save', newVal)
    },
    isSaving (newVal) {
      this.$emit('update-is-saving', newVal)
    },
    externalBackend: {
      handler (newVal) {
        this.$emit('update-external-backend', newVal)
      },
      deep: true,
    },
    'externalBackend.configuration.engine': {
      handler: 'handleEngineChange',
    },
  },
  async mounted () {
    await Promise.all([
      this.FETCH_AVAILABLE({ keyPath: 'credentials' }),
      this.FETCH_AVAILABLE({ keyPath: 'cloudProviders' }),
    ])
    await this.setup()
    await this.setInfraImportPreset()
    this.$setOriginalData('externalBackend')
    if (this.envCanonical) this.focusTypeSelect()
    this.isLoading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/externalBackend', [
      'CREATE_DEFAULT_EXTERNAL_BACKEND',
      'UPDATE_DEFAULT_EXTERNAL_BACKEND',
    ]),
    ...mapActions('organization/project', [
      'CREATE_EXTERNAL_BACKEND',
      'GET_EXTERNAL_BACKENDS',
      'GET_EXTERNAL_BACKEND',
      'UPDATE_EXTERNAL_BACKEND',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
    ]),
    async setup () {
      if (this.isDefault) return this.setExternalBackendData()

      if (this.isCreation) {
        await this.GET_EXTERNAL_BACKENDS()
        this.setEnv()
        return
      }

      const { externalBackendId } = this
      await this.GET_EXTERNAL_BACKEND({ externalBackendId })
      this.setExternalBackendData()
    },
    async create () {
      this.isSaving = true

      const { environmentCanonical, configuration, credentialCanonical, purpose } = this.externalBackend
      const externalBackend = {
        purpose,
        credential_canonical: credentialCanonical,
        configuration: _.omitBy(configuration, _.isNil),
      }

      if (this.useEnv) externalBackend.environment_canonical = environmentCanonical

      if (this.isDefault) {
        externalBackend.default = true
        await this.CREATE_DEFAULT_EXTERNAL_BACKEND(externalBackend)
      } else {
        externalBackend.project_canonical = this.projectCanonical
        await this.CREATE_EXTERNAL_BACKEND(externalBackend)
      }

      this.isSaving = false

      if (this.hasErrors) return

      if (this.isDefault) {
        this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.orgTerraform.created') })
        this.$setOriginalData('externalBackend')
      } else {
        this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.project.config.externalBackends.created') })
        this.$router.push({ name: 'projectConfiguration' })
      }
    },
    async update () {
      this.isSaving = true

      const { externalBackendId } = this
      const { environmentCanonical, configuration, credentialCanonical, purpose } = this.externalBackend
      const config = {
        id: externalBackendId,
        purpose,
        credential_canonical: credentialCanonical,
        configuration,
        ...(this.useEnv ? { environment_canonical: environmentCanonical } : {}),
      }

      if (this.isDefault) {
        config.default = true
        await this.UPDATE_DEFAULT_EXTERNAL_BACKEND({ externalBackendId, config })
      } else {
        config.project_canonical = this.projectCanonical
        await this.UPDATE_EXTERNAL_BACKEND({ externalBackendId, config })
      }

      this.isSaving = false

      if (this.hasErrors) return

      if (this.isDefault) {
        this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.orgTerraform.updated') })
        this.$setOriginalData('externalBackend')
      } else {
        this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.project.config.externalBackends.updated') })
        this.$router.push({ name: 'projectConfiguration' })
      }
    },
    async setInfraImportPreset () {
      const { engine } = this.presets?.configuration || {}
      if (!engine) return
      await this.$nextTick()
      this.externalBackend.configuration.engine = engine
      this.$set(this, 'externalBackend', _.$camelCaseKeys(this.presets))
      await this.$nextTick()
    },
    externalBackendIcon (type, engine) {
      const iconMapping = {
        GitLabHTTPStorage: 'gitlab',
        HTTPStorage: 'http',
      }

      if (engine in iconMapping) return iconMapping[engine]
      return type
    },
    clearEBFields () {
      const { configuration, credentialCanonical, ...externalBackend } = this.externalBackend
      this.$set(this, 'externalBackend', {
        ...externalBackend,
        credentialCanonical: null,
        configuration: {
          engine: null,
        },
      })
    },
    cancel () {
      if (!this.canCancel) return
      this.CLEAR_PROJ_ERRORS('externalBackend')
      this.$resetData('externalBackend')
      if (this.envCanonical) {
        this.setEnv()
        this.focusTypeSelect()
      }
      this.$v.externalBackend.$reset()
    },
    isAddingExternalBackendFor (engine) {
      return engine === this.externalBackend.configuration.engine
    },
    setEnv () {
      const { filteredEnvs, envCanonical } = this
      if (_.isEmpty(envCanonical)) return
      if (_.some(filteredEnvs, { canonical: envCanonical })) this.externalBackend.environmentCanonical = envCanonical
      else {
        const { envCanonical, ...params } = this.$route.params
        this.$router.replace({ ...this.$route, params })
      }
    },
    focusTypeSelect () {
      this.$refs?.externalBackendTypeSelect?.activateMenu()
      this.$refs?.externalBackendTypeSelect?.focus()
    },
    blurTypeSelect () {
      this.$refs?.externalBackendTypeSelect?.blur()
    },
    getDescriptionKey (engine, field) {
      const i18nKey = _.get(INFRAVIEW_EXTERNAL_BACKENDS, `${engine}.configurationFields.${field}`)
      return i18nKey ? this.$t(i18nKey) : ''
    },
    setExternalBackendData () {
      const storedExternalBackend = this.isDefault
        ? this.defaultExternalBackend
        : this.storeExternalBackend

      if (_.isEmpty(storedExternalBackend)) return

      const {
        configuration,
        configuration: { engine },
        credential_canonical: credentialCanonical,
        environment_canonical: environmentCanonical,
        purpose,
        jwt,
      } = storedExternalBackend
      const externalBackend = {
        jwt,
        purpose,
        credentialCanonical,
        configuration: { ...baseConfiguration[engine], ...configuration },
        ...(this.useEnv ? { environmentCanonical } : {}),
      }

      this.$set(this, 'externalBackend', externalBackend)
      for (const key in this.$v.externalBackend.configuration.$params) this.$v.externalBackend.configuration[key].$reset()
    },
    setInitialExternalBackendProps () {
      const { environmentCanonical, configuration: { engine } } = this.externalBackend
      if (!engine) return this.clearEBFields()

      const externalBackend = {
        purpose: _.get(INFRAVIEW_EXTERNAL_BACKENDS, `${engine}.purpose`, null),
        credentialCanonical: _.get(this.presets, 'credential_canonical', null),
        configuration: { engine, ...baseConfiguration[engine] },
      }
      if (this.useEnv) externalBackend.environmentCanonical = environmentCanonical

      this.$set(this, 'externalBackend', _.merge(externalBackend, this.prePopulateFormFields(engine)))
      this.$v.externalBackend.$reset()
    },
    prePopulateFormFields (engine) {
      const configuration = {}
      if (engine === 'GitLabHTTPStorage') {
        const urlSuggestion = 'https://gitlab.com/api/v4/projects/<TARGET-PROJECT-ID>/terraform/state/<TARGET-STATE-NAME>'
        this.$set(configuration, 'url', urlSuggestion)
        return { configuration }
      }
      if (!_.keys(baseConfiguration).includes(engine) || !this.useSuggestions) return

      for (const key of _(baseConfiguration?.[engine] || {}).keys().without('engine')) {
        this.$set(configuration, key, suggested(key, this.getFormFieldDefaults()))
      }
      return { configuration }
    },
    getFormFieldDefaults () {
      const {
        presets,
        orgCanonical,
        projectCanonical,
        externalBackend: {
          configuration: { region, url },
          environmentCanonical: env,
        },
      } = this

      return {
        orgCanonical,
        projectCanonical: projectCanonical || presets?.project_canonical,
        ...(this.useEnv ? { env } : {}),
        region: region || presets?.configuration?.region || '',
        url: url || presets?.configuration?.url || '',
      }
    },
    filterRegions ({ value, text }, queryText) {
      const searchText = queryText.toLowerCase()
      return value.toLowerCase().includes(searchText) || text.toLowerCase().includes(searchText)
    },
    handleEngineChange (_engine, oldEngine) {
      const isBeingInitiallySet = !this.isCreation && _.isEmpty(oldEngine)
      const isBeingReset = !this.$hasDataChanged('externalBackend.configuration')

      if (isBeingInitiallySet) return
      if (!isBeingReset) this.setInitialExternalBackendProps()

      // hack for an error where externalBackend.configuration.engine.$touch wouldn't work in InfraImport
      if (!_.$get(this, 'isInInfraImport.creation', false)) this.blurTypeSelect()
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Configuration details',
        externalBackendsConfig: {
          awsLabelSkipVerifySSL: 'Skip SSL verification',
          description: {
            forDesiredEnv: 'for the desired environment.',
            setupLocationOf: 'Setup the location of your',
            tfFile: 'Terraform State file',
          },
          fieldDescription: {
            awsBucket: 'The AWS bucket containing objects',
            awsEndpoint: 'A custom endpoint for the S3 API',
            awsKey: 'The S3 Key uniquely identifies an object in a bucket',
            awsRegion: 'The AWS region where the resource exists',
            awsS3ForcePathStyle: 'Always use path-style S3 URLs (<host>/<bucket> instead of <bucket>.<host>)',
            awsSkipVerifySSL: 'Allows the use of a self-signed certificate',
            azureBlob: 'The Azure blob contained in the container',
            azureContainer: 'The Azure container where the resource exists',
            gcpBucket: 'The GCP bucket containing objects',
            gcpObject: 'The GCP object uniquely identifying an object in a bucket',
            jwt: 'This JWT token can be used to connect to this Teraform backend.',
            swiftContainer: 'The Swift container where the resource exists',
            swiftObject: 'This is the path to the `.tfstate` file inside the Swift container',
            swiftRegion: 'The Swift region used to access your container',
            swiftSkipVerifySSL: 'Disable SSL verification if using a self-signed certificate or not verified by a certificate authority',
            httpUrl: 'HTTP url of the Terraform backend',
            gitlabHttpUrl: 'GitLab Terraform backend url',
          },
          s3StorageCompatibleOptions: 'S3 Storage compatible options',
          swiftLabelSkipVerifySSL: 'Skip SSL verification',
          title: 'InfraView configuration',
        },
      },
      es: {
        title: 'Detalles de configuracion',
        externalBackendsConfig: {
          awsLabelSkipVerifySSL: 'Omitir verificación SSL',
          description: {
            forDesiredEnv: 'para el ambiente deseado.',
            setupLocationOf: 'Configura la ubicación de tu',
            tfFile: 'Archivo de estado de Terraform',
          },
          fieldDescription: {
            awsBucket: 'El depósito de AWS que contiene objetos',
            awsEndpoint: 'Una dirección personalizada para la API S3',
            awsKey: 'La tecla S3 identifica de forma exclusiva un objeto en un depósito',
            awsRegion: 'La región de AWS donde existe el recurso',
            awsS3ForcePathStyle: 'Utilice siempre URL de S3 (<host>/<bucket> en vez de <bucket>.<host>)',
            awsSkipVerifySSL: 'Permite el uso de un certificado autofirmado',
            azureBlob: 'El blob azul contenido en el contenedor',
            azureContainer: 'El contenedor Azure donde el recurso existe',
            gcpBucket: 'El depósito de GCP que contiene objetos',
            gcpObject: 'El objeto GCP que identifica de forma exclusiva un objeto en un depósito',
            jwt: 'Este token JWT se puede usar para conectarse a este backend de Teraform.',
            swiftContainer: 'El contenedor Swift donde el recurso existe',
            swiftObject: 'Esta es la ruta al archivo `.tfstate` dentro del contenedor Swift',
            swiftRegion: 'La región Swift utilizada para acceder a su contenedor',
            swiftSkipVerifySSL: 'Deshabilite la verificación SSL si usa un certificado autofirmado o no es verificado por una autoridad de certificación',
            httpUrl: 'URL HTTP del backend de Terraform',
            gitlabHttpUrl: 'URL GitLab del backend de Terraform',
          },
          s3StorageCompatibleOptions: 'Opciones compatibles con S3 Storage',
          swiftLabelSkipVerifySSL: 'Omitir verificación SSL',
          title: 'Configuración de InfraView',
        },
      },
      fr: {
        title: 'Détails de configuration',
        externalBackendsConfig: {
          awsLabelSkipVerifySSL: 'Ignorer la vérification SSL',
          description: {
            forDesiredEnv: `pour l'environnement souhaité.`,
            setupLocationOf: `Configurez l'emplacement de votre`,
            tfFile: `Fichier d'état Terraform`,
          },
          fieldDescription: {
            awsBucket: 'Le compartiment AWS contenant des objets',
            awsEndpoint: `Une adresse personnalisée pour l'API S3`,
            awsKey: 'La clé S3 identifie de manière unique un objet dans un compartiment',
            awsRegion: 'La région AWS où la ressource existe',
            awsS3ForcePathStyle: 'Toujours utiliser des urls S3 "path-style" (<host>/<bucket> à la place de <bucket>.<host>)',
            awsSkipVerifySSL: `Permet l'utilisation d'un certificat auto-signé`,
            azureBlob: 'Le blob Azure contenu dans le conteneur',
            azureContainer: 'Le conteneur Azure où la ressource existe',
            gcpBucket: 'Le bucket GCP contenant des objets',
            gcpObject: `L'objet GCP identifiant de manière unique un objet dans un compartiment`,
            jwt: 'Ce jeton JWT peut être utilisé pour se connecter à ce backend Teraform.',
            swiftContainer: 'Le conteneur Swift où la ressource existe',
            swiftObject: `Ceci est le chemin d'accès au fichier \`.tfstate\` à l'intérieur du conteneur Swift`,
            swiftRegion: 'La région Swift utilisée pour accéder à votre conteneur',
            swiftSkipVerifySSL: 'Désactivez la vérification SSL si vous utilisez un certificat auto-signé ou non vérifié par une autorité de certification',
            httpUrl: 'URL HTTP du backend Terraform',
            gitlabHttpUrl: 'URL du backend Terraform de GitLab',
          },
          s3StorageCompatibleOptions: 'Options compatibles avec le stockage S3',
          swiftLabelSkipVerifySSL: 'Ignorer la vérification SSL',
          title: 'Configuration de InfraView',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.description {
  color: get-color("primary", "light-2");
  font-size: 16px;
  line-height: 1.5;
}

::v-deep {
  .v-card__title {
    padding: 24px 0 0;
  }

  .v-select__selections .v-avatar {
    justify-content: flex-start;
  }
}

.cy-details {
  &.new-header {
    .cy-details__content {
      padding: 0;

      .org-terraform-form & {
        padding: 5px;
      }
    }
  }
}

.fieldset {
  max-width: 450px;

  .org-terraform-form & {
    max-width: none;
  }
}

.form {
  &--fieldset {
    max-width: 450px;
    margin: 24px 0;
    padding: 24px;
    border: 1px solid get-color("grey", "light-1");
    border-radius: 4px;
    background-color: get-color("grey", "light-3");

    &__title {
      margin-bottom: 16px;
      padding-bottom: 4px;
      color: get-color("primary");
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
    }

    .org-terraform-form & {
      max-width: none;
    }

    ::v-deep .v-input--selection-controls:first-of-type {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__region {
    color: get-color("grey", "dark-2");
  }
}

.provider-icon {
  max-width: 40px;
  height: auto;
  max-height: 40px;
}

.external-backend-option__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
